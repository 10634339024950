import MobileDetect from 'mobile-detect';

import {Thumbnails} from 'shared/interfaces/planogram';
import {isTouchSupported, isMacOS} from './platform';
import {ProductImageVariants} from '../interfaces/product.interface';

export class BrowserUtils {
  private static _md: MobileDetect;

  private static get md() {
    if (!this._md) {
      this._md = new MobileDetect(navigator.userAgent);
    }
    return this._md;
  }

  static _isSafari: boolean | undefined;

  static isSafari(): boolean {
    if (this._isSafari === undefined) {
      this._isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    }
    return this._isSafari;
  }

  static isTablet(): boolean {
    return !!this.md.tablet();
  }

  static isMobile(): boolean {
    return !!this.md.mobile();
  }

  static isMobileSafari(): boolean {
    return this.isMobile() && this.isSafari();
  }

  static pickImageVariant(
    thumbnails: Thumbnails | ProductImageVariants,
    maxQuality: boolean = false,
    isEmailVariant?: boolean
  ): string {
    if (isEmailVariant) {
      return thumbnails.VARIANT_THUMBNAIL_PNG_600;
    }

    let imagePath = thumbnails.VARIANT_LARGE_WEBP;
    if (maxQuality && imagePath) {
      return imagePath;
    }

    const imageMediumPath = thumbnails.VARIANT_MEDIUM_WEBP;
    const imageSmallPath = thumbnails.VARIANT_SMALL_WEBP;

    if ((BrowserUtils.isTablet() || (isTouchSupported && isMacOS)) && imageMediumPath) {
      imagePath = imageMediumPath;
    }

    if (BrowserUtils.isMobile() && imageSmallPath) {
      imagePath = imageSmallPath;
    }

    return imagePath;
  }
}
